<template>
    <div class="container">
        <div class="create-title">
            <div>活动设置</div>
            <div class="btn">
                <el-button @click="routeChange(1)">取消</el-button>
                <el-button type="primary" @click="handleConfirm">保存</el-button>
            </div>
        </div>
        <div class="create-container">
            <colorful-title title="基本信息" class="colorful-title" />
            <div class="form-item">
                <div class="key">活动名称</div>
                <el-input class="value" v-model="editForm.name" placeholder="请输入活动名称"></el-input>
            </div>
            <div class="form-item">
                <div class="key">测评名额</div>
                <el-input class="value" v-model="editForm.count" placeholder="请输入测评名额数量上限"></el-input>
            </div>
            <colorful-title title="意向收集设置" class="colorful-title" />
            <div class="set-item">
                <el-checkbox-group v-model="checkList">
                    <el-checkbox label="interested_school_id" v-if="userType === USER_TYPE.ORG">园区意向</el-checkbox>
                    <el-checkbox label="flag_trial_course">参与体验课意向</el-checkbox>
                </el-checkbox-group>
            </div>
            <template v-if="checkList.includes('flag_trial_course')">
                <colorful-title title="体验课设置" class="colorful-title"/>
                <div class="form-item">
                    <div class="key">体验课程</div>
                    <div class="value">
                        <el-tag class="course-tag" v-for="(item, index) in editForm.courses" :key="index" closable
                            :disable-transitions="false" @close="handleRemoveCourse(index)">
                            {{item}}
                        </el-tag>
                        <el-input class="course-new-tag" v-if="inputVisible"
                            v-model="inputValue" ref="saveTagInput" placeholder="请输入课程名称"
                            @keyup.enter.native="handleAddCourse" @blur="handleAddCourse">
                        </el-input>
                        <el-button v-else type="primary" icon="el-icon-plus" class="add-tag-btn" @click="handleInputShow"></el-button>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Core from '@/core';
const USER_TYPE = Core.Const.USER_TYPE;

export default {
    components: {
        ColorfulTitle: () => import('@/components/common/ColorfulTitle')
    },
    data() {
        return {
            USER_TYPE,
            userType: Core.Data.getUserType(),
            detail: {},
            editForm: {
                id: '',
                name: '',
                count: '',
                courses: [],
            },
            checkList: [],
            inputVisible: false,
            inputValue: '',
        };
    },
    watch: {},
    created() {
        this.editForm.id = this.$route.query.id;
    },
    computed: {},
    mounted() {
        if (this.editForm.id) {
            this.getDetail();
        }
    },
    methods: {
        getDetail() {
            Core.operationApi.Evaluation.detail(this.editForm.id).then(
                (res) => {
                    console.log('getDetail res:', res)
                    this.editForm.name = res.detail.name;
                    this.editForm.count = res.detail.count;
                    this.editForm.courses = res.detail.experience_courses ? JSON.parse(res.detail.experience_courses) : [] ;
                    this.detail = res.detail;
                    this.checkList = JSON.parse(res.detail.form_field)
                }
            );
        },
        handleInputShow() {
            this.inputVisible = true;
            this.$nextTick(() => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        handleAddCourse() {
            if (this.inputValue) {
                this.editForm.courses.push(this.inputValue);
            }
            this.inputVisible = false;
            this.inputValue = '';
        },
        handleRemoveCourse(index) {
            this.editForm.courses.splice(index, 1);
        },
        routeChange(type) {
            switch (type) {
                case 1:
                    this.$router.go(-1);
                    break;
            }
        },
        handleConfirm() {
            console.log('editForm:', this.editForm);
            if (!this.editForm.name) {
                return this.$message.warning('请输入活动名称');
            }
            if (!this.editForm.count) {
                return this.$message.warning('请输入测试名额数量上限');
            }
            Core.operationApi.Evaluation.save(
                this.editForm.id,
                this.editForm.name,
                this.editForm.count,
                JSON.stringify(this.checkList),
                this.detail.playbill_url || '',
                this.detail.playbill_config || '',
                JSON.stringify(this.editForm.courses),
                1,
            ).then((res) => {
                this.$message.success('保存成功');
                this.routeChange(1);
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    padding: 0 30px 40px;
    .create-title {
        height: 62px;
        line-height: 62px;
        font-size: 20px;
        font-weight: 500;
        color: #323538;
        display: flex;
        justify-content: space-between;
    }
    .create-container {
        width: 100%;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #e1e7ec;
        overflow: hidden;
        padding: 0 60px 70px;
        box-sizing: border-box;

        .colorful-title {
            padding: 30px 0;
        }
        .form-item {
            display: flex;
            align-items: center;
            width: 100%;
            box-sizing: border-box;
            padding: 0 10px;

            + .form-item {
                margin-top: 16px;
            }

            .key {
                width: 72px;
            }
            .value {
                width: 500px;
                margin-left: 16px;
                display: flex;
            }
        }
        .set-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;
        }
    }
    .course-tag {
        min-width: 60px;
        height: 32px;
        line-height: 32px;
        padding: 0 10px;
        text-align: center;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 17px;
        border: 1px solid #e7edf0;
        color: #323538;
        margin-right: 14px;
        cursor: pointer;
        &.active {
            background: rgba(220, 111, 124, 0.2);
            color: #e06d7b;
            border-color: rgba(220, 111, 124, 0.2);
        }
        &.btn {
            width: 40px;
            padding: 0;
        }
    }
    .add-tag-btn {
        padding: 5px 9px;
        border-radius: 50%;
    }
    .course-new-tag {
        width: 140px;
    }
}
</style>

<style lang="scss">
.create-container {
    .set-item {
        .el-select .el-input {
            font-size: 12px;
        }
    }
}
</style>
